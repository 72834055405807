import React from 'react';
import Footer from '../Footer';
import {TinyButton as ScrollUpButton} from "react-scroll-up-button";
import 'react-tabs/style/react-tabs.css';
import '../Hula.css';
import '../Contact.css';
import '../Gmap.css';
import { Link } from 'react-router-dom'

export default function Hula(){
    return(
        <>
            <ScrollUpButton style={{background: '#DF8734', fill: 'rgb(255, 255, 255)', height: '32px', width: '32px'}} />
            <section className="hula lesson">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <p class="lesson__lesson_name">Sitemap</p>
                            <h2 className="lesson__top__title">サイトマップ</h2>
                        </div>
                    </div>
                </div>
                <div className="container main">
                    <div className="schedule">
                        <p><Link to='/' >トップページ </Link></p>
                    </div>
                </div>
                <div className="container main">
                    <div className="schedule">
                        <h3 className="subtitle">レッスン</h3>
                        <p><Link to='/yoga' >ヨーガ </Link></p>
                        <p><Link to='/hula' >フラダンス</Link></p>
                        <p><Link to='/Walk' >エクササイズウォーキング</Link></p>
                        <p><Link to='/foul' >フーレセラピー</Link></p>
                        <p><Link to='/schedule' >レッスンカレンダー</Link></p>
                        <br />
                    </div>
                </div>
                <div className="container main">
                    <div className="schedule">
                        <h3 className="subtitle">その他</h3>
                        <p><Link to='/sitemap' >サイトマップ</Link></p>
                        <p><Link to='/contact' >アクセス・お問い合わせ</Link></p>
                        <br />
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}