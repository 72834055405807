export const SliderData2 = [
    {
        link: './yoga',
        image: './images/img_top_slider_all_s.jpg'
    },
    {
        link: './yoga',
        image: './images/img_top_slider_yoga_s.jpg'
    },
    {
        link: './hula',
        image: './images/img_top_slider_hula_s.jpg'
    },
    {
        link: './walk',
        image: './images/img_top_slider_walk_s.jpg'
    },
    {
        link: './foul',
        image: './images/img_top_slider_foul_s.jpg'
    },
    {
        image: './images/img_top_slider_viita_s.jpg'
    },
    {
        link: './',
        image: './images/img_top_slider_top_sun_s.jpg'
    }
];
