import React, { useEffect, useState } from "react";
import "./Button.css"
import instagram from './images/Instagram_Glyph_Gradient_RGB.png';
import facebook from './images/f_logo_RGB-Blue_58.png';
import Twitter from './images/twitter-circle-blue.png';

export default function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scorlled upto given distance
  const toggleVisibility = () => {
    if (window.pageYOffset > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
  }, []);

/**
    <div className="scroll-to-top">
      {isVisible && (
        <div>


            <a href="tel:0369131377">
              <img
              src={facebook}
              alt="Go to top"
            /></a>
            <a href="tel:0369131377">
              <img
              src={Twitter}
              alt="Go to top"
            /></a>

        */

  return (
    <div className="scroll-to-top">
      {(
        <div>
          <div className="trialicon">
              <a href="https://studio-viita.com/trial">
              <img
              src="/images/cmn_icon_trial.png"
              alt="体験予約はコチラ！"
            /></a>
          </div>
          <div className="telicon">
            <a href="tel:0369131377">
              <img
              src="/images/cmn_icon_tel.png"
              alt="お電話はコチラ！"
            /></a>
          </div>
          <div className="sns">
            <a href="https://www.instagram.com/studio_viita/" target="_blank">
              <img
              src={instagram}
              alt="instagram"
            /></a>
          </div>
        </div>
      )}
    </div>
  );
}
