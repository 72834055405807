import React,{ useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button } from './Button'
import './Navbar.css'
/*import { animateScroll as scroll } from 'react-scroll';*/
import Logo from './images/logo_viita.svg';
import instagram from './images/Instagram_Glyph_Gradient_RGB.png';
import facebook from './images/f_logo_RGB-Blue_58.png';
import Twitter from './images/twitter-circle-blue.png';

/* 
<a href="" target="_blank" className="sns_viita"><img src={facebook} alt="facebook" /></a>
<a href="" target="_blank" className="sns_viita"><img src={Twitter} alt="Twitter" /></a>
*/

const Navbar = () => {
    const [click, setClick] = useState(false)
    const [button, setButton] = useState(true)
    const [navbar, setNavbar] = useState(false)


    const handleClick = () => {setClick(!click)}
    const closeMobileMenu = () => {setClick(false)}

    const showButton = () => {
        if(window.innerWidth <= 767){
            setButton(false)
        }else{
            setButton(true)
        }
    }

    useEffect(() => {
        showButton()
    },[])

    window.addEventListener('resize', showButton)
/*
    const toggleHome = () => {
        scroll.scrollToTop();
      }
*/
    const changeBackground = () => {
        if(window.scrollY >= 80){
            setNavbar(true)

        }else{
            setNavbar(false)
        }
    }
    window.addEventListener('scroll', changeBackground)

    return (
        <>
            <nav className={navbar ? 'navbar active' : 'navbar'}>
                <div className="navbar-container">
                    <Link to='/' className='navbar-logo' >
                        <h1 className="logo_viita"><img src={Logo} alt="西武池袋線石神井公園駅南口1分。ヨガ・フラダンス・ウォーキング・フーレセラピー。40代、50代、60代、70代、80代、シニア、初心者の方、大歓迎。" id="dsfoa" className="logo_viita" onClick={closeMobileMenu} /></h1>
                    </Link>
                    
                    <div className='sns_s'>
                        <a href="https://www.instagram.com/studio_viita/" target="_blank" className="sns_viita"><img src={instagram} alt="instagram" /></a>
                    </div>
                    <div className='menu-icon' onClick={handleClick}>
                        <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                    </div>
                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        <li className="menutitle responsive--sxs"><p>レッスン・セラピー</p></li>
                        <li className='nav-item'>
                            <Link to="/yoga" className='nav-links' onClick={closeMobileMenu}>
                            ヨーガ
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/hula' className='nav-links' onClick={closeMobileMenu}>
                            フラダンス
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/Walk' className='nav-links' onClick={closeMobileMenu}>
                            エクササイズ<br className="responsive--lllm" />ウォーキング
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/foul' className='nav-links' onClick={closeMobileMenu}>
                            フーレセラピー
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/schedule' className='nav-links' onClick={closeMobileMenu}>
                            レッスン<br className="responsive--lllm" />カレンダー
                            </Link>
                        </li>
                        <li className='nav-item responsive--sxs'>
                            <Link to='/trial' className='nav-links' onClick={closeMobileMenu}>
                            体験予約
                            </Link>
                        </li>
                        <li className="menutitle responsive--sxs"><p>その他</p></li>
                        <li className='nav-item responsive--sxs '>
                            <Link to='/sitemap' className='nav-links' onClick={closeMobileMenu}>
                            サイトマップ
                            </Link>
                        </li>
                        <li className='nav-item responsive--sxs'>
                            <Link to='/contact' className='nav-links' onClick={closeMobileMenu}>
                            アクセス・お問い合わせ
                            </Link>
                        </li>
                        </ul>
                    {button &&<Button
                        buttonStyle='btn--origan'
                        buttonSize='btn--medium'
                        >
                        アクセス・<br className="responsive--lllm" />お問い合わせ
                        </Button>}
                </div>
            </nav> 
        </>
    )
}

export default Navbar
