import { GoogleMap, LoadScript, InfoWindow } from "@react-google-maps/api";
import './Gmap.css';

const containerStyle = {
  height: "450px",
  width: "100%",
};

const center = {
  lat: 35.74319691226782,
  lng: 139.6063904611377,
};

const positionStudio = {
  lat: 35.74319691226782,
  lng: 139.6063904611377,
};  

const divStyle = {
  background: "white",
  fontSize: 7.5,
};

const MyComponent = () => {
  return (
    <div className="gmap">
      <div className="container">
        <div className="justify-content-center">
          <div>
            <h3 className="subtitle">アクセス情報</h3>
            <p>住所：〒177-0041　東京都練馬区石神井町3丁目20-17　KMスクエアビル6階</p>
            <p>電話番号：03-6913-1377</p>
            <br />
            <p>西武池袋線の石神井公園駅南口改札を出て、ロータリーに面した丸亀製麺が１Fに入っているビルの６Fにスタジオヴィータはあります。</p>
          </div>
        </div>
      </div>
      <LoadScript googleMapsApiKey="AIzaSyAx0t_bEbxgf7KNbztJ0xqPhjxmDyP0oIk">
        <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={17}>
          <InfoWindow position={positionStudio}>
            <div style={divStyle}>
              <h5>STUDIO VIITA</h5>
            </div>
          </InfoWindow>
        </GoogleMap>
      </LoadScript>
  </div>
    
  );
};

export default MyComponent;