import React from 'react'
import './yoyaku.css'

const Yoyaku = () => {
    return (
        <div className="container yoyaku py-3">
            <div className="row justify-content-center">
                <iframe id="yoyakufframe" name="yoyakufframe" title="yoyakufframe" width="100%" height={750} src="https://select-type.com/rsv/?id=X-GeNiKO298&c_id=159090&w_flg=1" frameBorder={0} />
            </div>
        </div>
    )
}

export default Yoyaku
