import React from 'react';
import Footer from '../Footer';
import {TinyButton as ScrollUpButton} from "react-scroll-up-button";
import 'react-tabs/style/react-tabs.css';
import '../Hula.css';
import '../Gmap.css';
import '../Contact.css';
import Yoyaku from '../Yoyaku';
import MyComponent from '../Gmap';

export default function Hula(){
    return(
        <>
           <ScrollUpButton style={{background: '#DF8734', fill: 'rgb(255, 255, 255)', height: '32px', width: '32px'}} />
            <link rel="stylesheet" href="https://studio-viita.com/static/css/trial.css" />
            <section className="hula lesson">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <p class="lesson__lesson_name">Trial</p>
                            <h2 className="lesson__top__title">体験レッスン申込み専用カレンダー</h2>
                        </div>
                    </div>
                </div>
                <Yoyaku />
                <MyComponent />
            </section>
            <Footer />
        </>
    )
}