export const SliderData1 = [
    {
        link: '/yoga',
        image: './images/img_top_slider_all.jpg'
    },
    {
        link: '/yoga',
        image: './images/img_top_slider_yoga.jpg'
    },
    {
        link: '/hula',
        image: './images/img_top_slider_hula.jpg'
    },
    {
        link: '/walk',
        image: './images/img_top_slider_walk.jpg'
    },
    {
        link: '/foul',
        image: './images/img_top_slider_foul.jpg'
    },
    {
        image: './images/img_top_slider_viita.jpg'
    },
    {
        link: '/',
        image: './images/img_top_slider_top_sun.jpg'
    }
];
