import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import './default.css';
import history from './components/history';
import ToTop from './components/Totop';

ReactDOM.render(
  <React.StrictMode>
    <Router history={history} basename={'/'}>
      <ToTop />
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);