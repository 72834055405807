import React from 'react'
import '../../App.css'
import '../Home.css'
import '../Gmap.css';
import Cards from '../Cards'
import Footer from '../Footer'
import ImageSlider from '../ImageSlider'
import { SliderData1 } from '../SliderData1'
import ImageSlider2 from '../ImageSlider2'
import { SliderData2 } from '../SliderData2'
import {TinyButton as ScrollUpButton} from "react-scroll-up-button";
import Yoyaku from '../Yoyaku'
import MyComponent from '../Gmap'
import NewTitle from '../NewTitle'

export default function Home() {
    
    return(
        <>
            <ScrollUpButton style={{background: '#DF8734', fill: 'rgb(255, 255, 255)', height: '32px', width: '32px'}} />
            <div className="responsive--lllm">
                <ImageSlider slides={SliderData1} />
            </div>
            <div className="responsive--sxs">
                <ImageSlider2 slides={SliderData2} />
            </div>
            <div className="responsive--lllm">
                <div className="top_denmark">
                    <div class="top_denmark__l"><a href='https://y-speaknow-forever.jimdosite.com/' target='_blank'><img src="./images/top_img_denmark_bn.jpg" /></a></div>
                    <div class="top_denmark__r"><a href='https://line.me/R/ti/p/@434blbcd' target='_blank'><img src="./images/top_brn_denmark_yoyaku.jpg" /></a></div>
                </div>
            </div>
            <div className="responsive--sxs">
                <div className="top_denmark_s">
                    <div class="top_denmark_bn"><a href='https://y-speaknow-forever.jimdosite.com/' target='_blank'><img src="./images/top_img_denmark_bn_s.jpg" /></a></div>
                    <div class="top_denmark_btn"><a href='https://line.me/R/ti/p/@434blbcd' target='_blank'><img src="./images/top_brn_denmark_yoyaku_s.jpg" /></a></div>
                </div>
            </div>
            <NewTitle />
            <Cards />
            <div className="container anshin mb-5">
                <h3 className=" text-center">Studio Viita <br class="responsive--sxs" />安心、安全のための<br class="responsive--sxs" />とりくみ。</h3>
                <div className="d-flex flex-row justify-content-center mt-5">
                    <div className="mx-2 top_img_anshin">
                        <img src="./images/top_img_1.jpg" alt="yoga" max-width="100px" />
                    </div>
                    <div className="mx-2 top_img_anshin">
                        <img src="./images/top_img_2.jpg" alt="yoga" />
                    </div>
                    <div className="mx-2 top_img_anshin">
                        <img src="./images/top_img_3.jpg" alt="yoga" />
                    </div>
                    <div className="mx-2 top_img_anshin">
                        <img src="./images/top_img_4.jpg" alt="yoga" />
                    </div>
                    <div className="mx-2 top_img_anshin">
                        <img src="./images/top_img_5.jpg" alt="yoga" />
                    </div>
                    <div className="mx-2 top_img_anshin">
                        <img src="./images/top_img_6.jpg" alt="yoga" />
                    </div>
                </div>
            </div>
            <h3 className="subtitle">体験レッスン申込み専用カレンダー</h3>
            <Yoyaku />
            <MyComponent />
            <Footer />
        </>
    )
}
