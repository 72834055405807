import React from 'react';
import Footer from '../Footer';
import {TinyButton as ScrollUpButton} from "react-scroll-up-button";
import Image from 'react-bootstrap/Image';
import 'react-tabs/style/react-tabs.css';
import '../Hula.css';
import '../Gmap.css';
import Yoyaku from '../Yoyaku';
import MyComponent from '../Gmap';

export default function Hula(){
    return(
        <>
            <ScrollUpButton style={{background: '#DF8734', fill: 'rgb(255, 255, 255)', height: '32px', width: '32px'}} />
            <section className="hula lesson">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <p class="lesson__lesson_name">Hula dance</p>
                            <h2 className="lesson__top__title">初心者やシニアでも気軽に始められるフラダンス。</h2>
                        </div>
                    </div>
                </div>
                <div class="lesson__topimage">
                    <img src="./images/img_hula_top.jpg" alt="" class="hula__top__img" />
                </div>
                <div className="container main">
                    <div className="row justify-content-center">
                        <div className="m-3 ">
                            <p className="my-3">フラダンスは土曜日の昼クラス。ハワイが大好きで、古典フラ、現代フラを踊っています。雄大なハワイの大自然を感じながら、明るい日差しの中伸び伸びと育つハワイの植物のように、この新しいスタジオで皆さまと一緒に踊る事を心から愉しみたいと思います</p>
                        </div>
                        <div className="price">
                            <h3>レッスン日時</h3>
                            <p className='my-3'>・土曜日／14時30分～15時30分</p>
                        </div>
                        <div className="price">
                            <h3>料金</h3>
                            <p className='my-3'>・入会金（会員登録時）：税込2,200円<br />
                            ・レッスン料金（1時間クラス）：税込6,600円／月（税込2,200円×3回分）※2024年4月改定<br />
                             ※お申し込みと同時に会員カードを発行します。</p>
                        </div>
                        <div className="present">
                            <img src="./images/lesson_img_present.png" alt="" />
                        </div>
                    </div>
                </div>
                <div className="gray">
                <div className="container main">
                    <div className="justify-content-center my-5 instructor">
                        <h3 className='bold py-3 mt-1 mb-4 title'>Studio Viitaの<br className="responsive--lm" />フラダンスインストラクターを紹介します。</h3>
                        <div className="col-lg-12">
                            <div className="row justify-content-center m-1 border">
                                <div className="m-3 p-3">
                                    <h4 className="name">梓沢真琴<span>（アズサワ　マコト）</span></h4>
                                    <div className="subm">
                                        <h5 class="my-2">&lt;経歴&gt;</h5>
                                        <p>古典フラ、現代フラ歴8年。ハワイのKumu Leato S.Saviniに従事。</p>
                                        <h5 class="my-2">&lt;メッセージ&gt;</h5>
                                        <p>ハワイが大好きでフラを踊っています。<br />
雄大なハワイの自然を感じながら踊り、ハワイ文化やハワイの王族を歌った古典フラも学んで来ました。明るい日差しの中、伸び伸びと育つハワイの植物のように、このスタジオで踊ることを楽しみたいと思います。<br />
ハワイが好きな方、身体を動かして健康になりたい方、ぜひご参加をお待ちしています。</p>
                                        <div className="flex image">
                                            <div className="col2-img-50">
                                                <Image src="./images/img_hula_image01.jpg" alt="hula" fluid/>
                                                </div>
                                                <div className="col2-img-50">
                                                <Image src="./images/img_hula_image02.jpg" alt="yoga" fluid/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <h3 className="subtitle">体験レッスン申込み専用カレンダー</h3>
                <Yoyaku />
                <MyComponent />
            </section>
            <Footer />
        </>
    )
}