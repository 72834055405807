import React from 'react';
import Footer from '../Footer';
import {TinyButton as ScrollUpButton} from "react-scroll-up-button";
import Image from 'react-bootstrap/Image';
import 'react-tabs/style/react-tabs.css';
import '../Hula.css';
import '../Gmap.css';
import Yoyaku from '../Yoyaku';
import MyComponent from '../Gmap';

export default function lesson(){
    return(
        <>
            <ScrollUpButton style={{background: '#DF8734', fill: 'rgb(255, 255, 255)', height: '32px', width: '32px'}} />
            <section className="yoga lesson">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <p class="lesson__lesson_name">Exercise Walking</p>
                            <h2 className="lesson__top__title">美しさと健康をつくるエクササイズウォーキング。</h2>
                        </div>
                    </div>
                </div>
                <div class="lesson__topimage">
                    <img src="./images/img_walk_top.jpg" alt="" class="lesson__top__img" />
                </div>
                <div className="container main">
                    <div className="row justify-content-center">
                        <div className="m-3 ">
                            <p className="my-3">エクササイズウォーキングは、日曜日の朝クラス。 毎日必ず行うことで自然に「美」と「健康」が 手に入るのが、エクササイズウォーキングです。 まずは歩き方チェックから。 「歩き方」を習ったことがない方が多いからこそ、 その「効果」は絶大です。</p>
                        </div>
                        <div className="price">
                            <h3>レッスン日時</h3>
                            <p className='my-3'>・月二回、隔週／日曜日／10時30分～12時</p>
                        </div>
                        <div className="price">
                            <h3>料金</h3>
                            <p className='my-3'>
                            ・入会金（会員登録時）：税込2,200円<br />
                            ・レッスン料金（1時間30分クラス） ：税込5,500円／月（税込2,750円×2回分）<br />
                             ※お申し込みと同時に会員カードを発行します。</p>
                        </div>
                        <div className="present">
                            <img src="./images/lesson_img_present.png" alt="" />
                        </div>
                    </div>
                </div>
                <div className="gray">
                <div className="container main">
                    <div className="justify-content-center my-5 instructor">
                        <h3 className='bold py-3 mt-1 mb-4 title'>Studio Viitaのウォーキングインストラクターを紹介します。</h3>
                        <div className="col-lg-12">
                                <div className="row justify-content-center m-1 border">
                                    <div className="m-3 p-3">
                                        <h4 className="name">竹島由梨<span>（タケシマ　ユリ）YURI</span></h4>
                                        <div className="subm">
                                            <h5 class="my-2">&lt;経歴&gt;</h5>
                                            <p>ファッションモデル。<br />Miss World 2020　埼玉特別賞。<br />2018年にワタナベエンターテインメントカレッジ卒業後、モデルとして広告やファッションショーに出演。</p>
                                            <h5 class="my-2">&lt;メッセージ&gt;</h5>
                                            <p>176cmの長身を活かして、大学バスケ部では、マネージャーを務めていました。 猫と宇宙が好きです。ホルンも吹きます。即興ハモリと外国語を覚えることが得意です。 常識に捉われないことを大切に、「素直で本気」という言葉をモットーに生きています。</p>
                                            <div className="flex image">
                                                <div className="col2-img-50">
                                                    <Image src="./images/img_walk_image01.jpg" alt="lesson" fluid/>
                                                    </div>
                                                    <div className="col2-img-50">
                                                    <Image src="./images/img_walk_image02.jpg" alt="yoga" fluid/>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <h3 className="subtitle">体験レッスン申込み専用カレンダー</h3>
                <Yoyaku />
                <MyComponent />
            </section>
            <Footer />
        </>
    )
}