import React from 'react';
import '../../App.css';
import Footer from '../Footer';

export default function SignUp(){
    return(
        <>
        <h1 className='sign-up'>お問い合わせ</h1>
        <Footer />
        </>
    )
}