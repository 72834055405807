import React from 'react'
import './NewTitle.css'
import { Link } from 'react-router-dom'

const sub = document.getElementById("sub");
function func1() {
    sub.style.width = sub.contentWindow.document.body.scrollWidth + "px";
    sub.style.height = sub.contentWindow.document.body.scrollHeight + "px";
}

const NewTitle = () => {
    return (
        <section className="topics">
        <div className="container topics_body">
            <div className="col-2 topics_head">
                <h2>Topics</h2>
            </div>
            <div className="topics_body__inwrap">
                <iframe id="topicsframe" name="topicsframe" title="topicsframe" width="100%" height="100%" onload="func1()" src="https://studio-viita.com/calendar/topics/" frameBorder={0} />
                <div className='col-12 text-right topics_footer'><Link to='/topics'>&gt;TOPICS一覧</Link></div> 
            </div>
        </div>
        </section>
    )
}

export default NewTitle
