import React from 'react';
import Footer from '../Footer';
import {TinyButton as ScrollUpButton} from "react-scroll-up-button";
import Image from 'react-bootstrap/Image'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import '../Yoga.css';
import '../Hula.css';
import '../Gmap.css';
import Yoyaku from '../Yoyaku';
import MyComponent from '../Gmap';

export default function Yoga(){
    return(
        <>
            <ScrollUpButton style={{background: '#DF8734', fill: 'rgb(255, 255, 255)', height: '32px', width: '32px'}} />
            <section className="yoga lesson">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <p class="lesson__lesson_name">yoga</p>
                            <h2 className="lesson__top__title">人間に本来備わる免疫力を自然に高めるヨーガ。</h2>
                        </div>
                    </div>
                </div>
                <div class="lesson__topimage">
                    <img src="./images/img_yoga_top_202408.jpg" alt="" class="lesson__top__img" />
                </div>
                <div className="container main">
                    <div className="row justify-content-center">
                        <div className="m-3 lesson_title">
                            <p className="my-3">皆様のご希望・年齢など考慮したレッスンをいたします。ヨーガは、朝、 昼、夜の３部制、計７クラスからお選び頂けます。</p>
                        </div>
                        <div className="price">
                            <h3>レッスン日時</h3>
                            <p className='my-3'>
                            ・月曜日／10時30分～11時45分／濱川<br />
                            ・月曜日／14時30分～15時45分／近藤<br />
                            ・火曜日／18時45分～20時00分／KAGARI<br />
                            ・木曜日／10時30分～11時45分／KAGARI<br />
                            ・金曜日／10時30分～11時45分／近藤<br />
                            ・金曜日／14時30分～15時45分／武田<br />
                            ・土曜日／10時45分～12時00分／KAGARI<br />
                            ※ヨーガは続けることで効果が出ます。可能な限り月4回のレッスンをお勧めします。<br className="responsive--lllm" />ご都合がつかずレッスンを受けられない時は、他の日時にご自由に振り替えられます。 </p>
                        </div>
                        <div className="price">
                            <h3>料金</h3>
                            <p className='my-3'>・入会金（会員登録時）：税込2,200円<br />
                            ・レッスン料金（1時間15分クラス）：税込8,800円／月（税込2,200円×4回分）※2024年4月改定<br />
                             ※お申し込みと同時に会員カードを発行します。</p>
                        </div>
                        <div className="present">
                            <img src="./images/lesson_img_present.png" alt="" />
                        </div>
                    </div>
                </div>
                <div className="gray">
                <div className="container main">
                    <div className="justify-content-center my-5 instructor">
                        <h3 className='bold py-3 mt-1 mb-4 title'>Studio Viitaの<br className="responsive--lm" />ヨーガインストラクターを紹介します。</h3>
                        <div className="col-lg-12">
                        <Tabs>
                            <TabList>
                            <Tab>濱川量子<br />（ハマカワ　カズコ）</Tab>
                            <Tab>武田真弓<br />（タケダ　マユミ）</Tab>
                            <Tab>KAGARI<br />（カガリ）</Tab>
                            <Tab>近藤美佐子<br />（コンドウ　ミサコ）</Tab>
                            </TabList>

                            <TabPanel>
                                <div className="row justify-content-center m-1 border">
                                    <div className="col-lg-4 m-3 p-3 instructor_wrap">
                                        <Image src="./images/img_yoga_inst_hamakawa.jpg" alt="yoga" className="img_instructor"/>
                                    </div>
                                    <div className="description_wrap col-lg-7 m-3 p-3">
                                        <h4 className="name">濱川量子<span>（ハマカワ　カズコ）</span></h4>
                                        <div className="subm">
                                            <h5 class="my-2">&lt;経歴&gt;</h5>
                                            <p>1989年より、健康ヨーガ教室主宰。<br />
    1992年12月　南インドシュリラマナ道場・ラーマクリシュナミッションにて研修<br />
    1996年　日本ヨーガ禅道友会の教師に認定<br />
    1997年　北インド　ヒマラヤにてヨーガ研修<br />
    2008年　インド　リシケシのシヴァナンダアシュラムにて研修<br />
    2018年　健康ヨーガ教室30周年記念祭開催</p>
    <h5 class="my-2">&lt;メッセージ&gt;</h5>
                                            <p>若い頃から旅行が何よりも好きでした。中でも自然にひたる旅に心惹かれます。<br />国内の旅のほかにアメリカ、ヨーロッパ、インド、中国、カンボジア、ペルーを訪れました。<br />
    80代になってからもドイツ、スリランカを旅しました。<br />
    病弱だった幼少期には考えられないほど、健康に恵まれ、<br />
    楽しい旅を生涯続けられた幸せは、何よりもヨーガのおかげと感じています。</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="row justify-content-center m-1 border">
                                    <div className="col-lg-4 m-3 p-3 instructor_wrap">
                                        <Image src="./images/img_yoga_inst_takeda.jpg" alt="yoga" className="img_instructor"/>
                                    </div>
                                    <div className="description_wrap col-lg-7 m-3 p-3">
                                        <h4 className="name">武田真弓<span>（タケダ　マユミ）</span></h4>
                                        <div className="subm">
                                            <h5 class="my-2">&lt;経歴&gt;</h5>
                                            <p>メニエール病の予後ヨーガと出会い、2004年　髙山葉子氏に師事。<br />
師のストイックなライフスタイルに感銘をうける。<br />
2005年よりヨーガセラピーを学び、その後様々なヨーガの研修に参加。<br />
2010年日本ヨーガ禅道友会入会。本格的に佐保田鶴治先生のヨーガ禅を学び、2016年より認定教師。<br />
ヨーガ指導歴13年。<br />
2016年より東京信用金庫ヨーガ教室講師。<br />
取得したディプロマは「ヨーガ・インストラクター」「ヨーガ・セラピー・インストラクター」どちらも(インド中央政府技術者認定)</p>
<h5 class="my-2">&lt;メッセージ&gt;</h5>
                                            <p>ジャンルを問わず本はよく読みますが、なぜか歴史小説が好きで、宮城谷昌光さんの古代中国ものにハマったことも…<br />
家から見える大きな柿の木に、いろいろな鳥のさえずりがして、日々いやされています。</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="row justify-content-center m-1 border">
                                    <div className="col-lg-4 m-3 p-3 instructor_wrap">
                                        <Image src="./images/img_yoga_inst_kagari.jpg" alt="yoga" className="img_instructor"/>
                                    </div>
                                    <div className="description_wrap col-lg-7 m-3 p-3">
                                        <h4 className="name">KAGARI<span>（カガリ）</span></h4>
                                        <div className="subm">
                                            <h5 class="my-2">&lt;経歴&gt;</h5>
                                            <p>1987年　日本ヨーガ禅道友会に入門<br />
1989年　健康ヨーガ教室に入門<br />
1992年12月　南インドシュリラマナ道場・ラーマクリシュナミッションにて研修<br />
1994年～1997年　NHKカルチャーセンター青山教室にて指導<br />
1997年北インド　ヒマラヤにてヨーガ研修<br />
2000年　インド　ロナワラ　カイバリヤダーマヨーガ大学にて短期ディプロマ取得<br />
2000年　日本ヨーガ禅道友会の教師に認定<br />
2002年～スポーツプレックス代々木店店舗、コナミスポーツクラブ、フージャースウェルネス＆スポーツアリス店舗にて指導<br />
2008年　インド　リシケシのシヴァナンダアシュラムにて研修<br />
2015年よりパドマの会主宰</p>
<h5 class="my-2">&lt;メッセージ&gt;</h5>
                                            <p>20代は登山とスキーに夢中でした。リハビリでヨーガを続けるうちに、<br />
調身・調息・調心の素晴らしさに気づきました。<br />
さらに、インドの大地に出会い、風土、文化や哲学にも魅力を感じます。<br />
何千年という悠久の流れの中に身をおく心地よさ、<br />
ヨーガ禅の奥深さをご一緒に味わえれば幸いです。</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="row justify-content-center m-1 border">
                                    <div className="col-lg-4 m-3 p-3 instructor_wrap">
                                        <Image src="./images/img_yoga_inst_kondou.jpg" alt="yoga" className="img_instructor"/>
                                    </div>
                                    <div className="description_wrap col-lg-7 m-3 p-3">
                                        <h4 className="name">近藤美佐子<span>（コンドウ　ミサコ）</span></h4>
                                        <div className="subm">
                                            <h5 class="my-2">&lt;経歴&gt;</h5>
                                            <p>健康のために始めたヨーガが、自身のライフスタイルに欠かせないものだと気づいた事から、<br />
・2010年日本ヨーガ禅道友会に入門し、本格的に佐保田鶴治先生の教えに従い、学びを深める。<br />
また、予防医学や食事など、健康のための生活習慣ついて興味があり、<br />
・2019年健康管理士一級指導員を取得し、ヨーガ指導の傍ら、健康のアドバイスなどを行っている。<br />
・ヨーガ歴１８年<br />
・ヨーガ指導歴8年<br />
・2020年日本ヨーガ禅道友会認定教師となり、日頃からヨーガを少しでも生活の中に取り入れて、「健康＝幸せ」である事、未来の自分をより生き生きしてもらうため、わかりやすいヨーガをお伝えするよう努めている。</p>
<h5 class="my-2">&lt;メッセージ&gt;</h5>
                                            <p>自然に触れる事が好きで、散歩をしたり、ガーデニングをしながら、草木、花、鳥を眺めていると幸せな気分です。<br />
２０年以上続けている手作り酵素は、春に野草を摘みに行きます。<br />
春の芽吹き、生命を感じながら春の山や野原を散策する、毎年の楽しみとなっています。<br />
人間本来の必要なエネルギーとヨーガ禅で、元気に過ごしています。全てに感謝の毎日です。</p>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="row justify-content-center m-1 border">
                                    <div className="col-lg-4 m-3 p-3 instructor_wrap">
                                        <Image src="./images/img_yoga_inst_takeda.jpg" alt="yoga" className="img_instructor"/>
                                    </div>
                                    <div className="description_wrap col-lg-7 m-3 p-3">
                                        <h4 className="name">武田真弓<span>（タケダ　マユミ）</span></h4>
                                        <div className="subm">
                                            <h5 class="my-2">&lt;経歴&gt;</h5>
                                            <p>メニエール病の予後ヨーガと出会い、2004年　髙山葉子氏に師事。<br />
    師のストイックなライフスタイルに感銘をうける。<br />
    2005年よりヨーガセラピーを学び、その後様々なヨーガの研修に参加。<br />
    2010年日本ヨーガ禅道友会入会。本格的に佐保田鶴治先生のヨーガ禅を学び、2016年より認定教師。<br />
    ヨーガ指導歴13年。<br />
    2016年より東京信用金庫ヨーガ教室講師。<br />
    取得したディプロマは「ヨーガ・インストラクター」「ヨーガ・セラピー・インストラクター」どちらも(インド中央政府技術者認定)</p>
    <h5 class="my-2">&lt;メッセージ&gt;</h5>
                                            <p>ジャンルを問わず本はよく読みますが、なぜか歴史小説が好きで、宮城谷昌光さんの古代中国ものにハマったことも…<br />
    家から見える大きな柿の木に、いろいろな鳥のさえずりがして、日々いやされています。</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="row justify-content-center m-1 border">
                                    <div className="col-lg-4 m-3 p-3 instructor_wrap">
                                        <Image src="./images/img_yoga_inst_hamakawa.jpg" alt="yoga" className="img_instructor"/>
                                    </div>
                                    <div className="description_wrap col-lg-7 m-3 p-3">
                                        <h4 className="name">濱川量子<span>（ハマカワ　カズコ）</span></h4>
                                        <div className="subm">
                                            <h5 class="my-2">&lt;経歴&gt;</h5>
                                            <p>1989年より、健康ヨーガ教室主宰。<br />
    1992年12月　南インドシュリラマナ道場・ラーマクリシュナミッションにて研修<br />
    1996年　日本ヨーガ禅道友会の教師に認定<br />
    1997年　北インド　ヒマラヤにてヨーガ研修<br />
    2008年　インド　リシケシのシヴァナンダアシュラムにて研修<br />
    2018年　健康ヨーガ教室30周年記念祭開催</p>
    <h5 class="my-2">&lt;メッセージ&gt;</h5>
                                            <p>若い頃から旅行が何よりも好きでした。中でも自然にひたる旅に心惹かれます。<br />国内の旅のほかにアメリカ、ヨーロッパ、インド、中国、カンボジア、ペルーを訪れました。<br />
    80代になってからもドイツ、スリランカを旅しました。<br />
    病弱だった幼少期には考えられないほど、健康に恵まれ、<br />
    楽しい旅を生涯続けられた幸せは、何よりもヨーガのおかげと感じています。</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="row justify-content-center m-1 border">
                                    <div className="col-lg-4 m-3 p-3 instructor_wrap">
                                        <Image src="./images/img_yoga_inst_kagari.jpg" alt="yoga" className="img_instructor"/>
                                    </div>
                                    <div className="description_wrap col-lg-7 m-3 p-3">
                                        <h4 className="name">KAGARI<span>（カガリ）</span></h4>
                                        <div className="subm">
                                            <h5 class="my-2">&lt;経歴&gt;</h5>
                                            <p>1987年　日本ヨーガ禅道友会に入門<br />
    1989年　健康ヨーガ教室に入門<br />
    1992年12月　南インドシュリラマナ道場・ラーマクリシュナミッションにて研修<br />
    1994年～1997年　NHKカルチャーセンター青山教室にて指導<br />
    1997年北インド　ヒマラヤにてヨーガ研修<br />
    2000年　インド　ロナワラ　カイバリヤダーマヨーガ大学にて短期ディプロマ取得<br />
    2000年　日本ヨーガ禅道友会の教師に認定<br />
    2002年～スポーツプレックス代々木店店舗、コナミスポーツクラブ、フージャースウェルネス＆スポーツアリス店舗にて指導<br />
    2008年　インド　リシケシのシヴァナンダアシュラムにて研修<br />
    2015年よりパドマの会主宰</p>
    <h5 class="my-2">&lt;メッセージ&gt;</h5>
                                            <p>20代は登山とスキーに夢中でした。リハビリでヨーガを続けるうちに、<br />
    調身・調息・調心の素晴らしさに気づきました。<br />
    さらに、インドの大地に出会い、風土、文化や哲学にも魅力を感じます。<br />
    何千年という悠久の流れの中に身をおく心地よさ、<br />
    ヨーガ禅の奥深さをご一緒に味わえれば幸いです。</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="row justify-content-center m-1 border">
                                <div className="col-lg-4 m-3 p-3 instructor_wrap">
                                        <Image src="./images/img_yoga_inst_kondou.jpg" alt="yoga" className="img_instructor"/>
                                    </div>
                                    <div className="description_wrap col-lg-7 m-3 p-3">
                                        <h4 className="name">近藤美佐子<span>（コンドウ　ミサコ）</span></h4>
                                        <div className="subm">
                                            <h5 class="my-2">&lt;経歴&gt;</h5>
                                            <p>健康のために始めたヨーガが、自身のライフスタイルに欠かせないものだと気づいた事から、<br />
    ・2010年日本ヨーガ禅道友会に入門し、本格的に佐保田鶴治先生の教えに従い、学びを深める。<br />
    また、予防医学や食事など、健康のための生活習慣ついて興味があり、<br />
    ・2019年健康管理士一級指導員を取得し、ヨーガ指導の傍ら、健康のアドバイスなどを行っている。<br />
    ・ヨーガ歴１８年<br />
    ・ヨーガ指導歴8年<br />
    ・2020年日本ヨーガ禅道友会認定教師となり、日頃からヨーガを少しでも生活の中に取り入れて、「健康＝幸せ」である事、未来の自分をより生き生きしてもらうため、わかりやすいヨーガをお伝えするよう努めている。</p>
    <h5 class="my-2">&lt;メッセージ&gt;</h5>
                                            <p>自然に触れる事が好きで、散歩をしたり、ガーデニングをしながら、草木、花、鳥を眺めていると幸せな気分です。<br />
    ２０年以上続けている手作り酵素は、春に野草を摘みに行きます。<br />
    春の芽吹き、生命を感じながら春の山や野原を散策する、毎年の楽しみとなっています。<br />
    人間本来の必要なエネルギーとヨーガ禅で、元気に過ごしています。全てに感謝の毎日です。</p>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="row justify-content-center m-1 border">
                                    <div className="col-lg-4 m-3 p-3 instructor_wrap">
                                        <Image src="./images/img_yoga_inst_kagari.jpg" alt="yoga" className="img_instructor"/>
                                    </div>
                                    <div className="description_wrap col-lg-7 m-3 p-3">
                                        <h4 className="name">KAGARI<span>（カガリ）</span></h4>
                                        <div className="subm">
                                            <h5 class="my-2">&lt;経歴&gt;</h5>
                                            <p>1987年　日本ヨーガ禅道友会に入門<br />
1989年　健康ヨーガ教室に入門<br />
1992年12月　南インドシュリラマナ道場・ラーマクリシュナミッションにて研修<br />
1994年～1997年　NHKカルチャーセンター青山教室にて指導<br />
1997年北インド　ヒマラヤにてヨーガ研修<br />
2000年　インド　ロナワラ　カイバリヤダーマヨーガ大学にて短期ディプロマ取得<br />
2000年　日本ヨーガ禅道友会の教師に認定<br />
2002年～スポーツプレックス代々木店店舗、コナミスポーツクラブ、フージャースウェルネス＆スポーツアリス店舗にて指導<br />
2008年　インド　リシケシのシヴァナンダアシュラムにて研修<br />
2015年よりパドマの会主宰</p>
<h5 class="my-2">&lt;メッセージ&gt;</h5>
                                            <p>20代は登山とスキーに夢中でした。リハビリでヨーガを続けるうちに、<br />
調身・調息・調心の素晴らしさに気づきました。<br />
さらに、インドの大地に出会い、風土、文化や哲学にも魅力を感じます。<br />
何千年という悠久の流れの中に身をおく心地よさ、<br />
ヨーガ禅の奥深さをご一緒に味わえれば幸いです。</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="row justify-content-center m-1 border">
                                    <div className="col-lg-4 m-3 p-3 instructor_wrap">
                                        <Image src="./images/img_yoga_inst_hamakawa.jpg" alt="yoga" className="img_instructor"/>
                                    </div>
                                    <div className="description_wrap col-lg-7 m-3 p-3">
                                        <h4 className="name">濱川量子<span>（ハマカワ　カズコ）</span></h4>
                                        <div className="subm">
                                            <h5 class="my-2">&lt;経歴&gt;</h5>
                                            <p>1989年より、健康ヨーガ教室主宰。<br />
1992年12月　南インドシュリラマナ道場・ラーマクリシュナミッションにて研修<br />
1996年　日本ヨーガ禅道友会の教師に認定<br />
1997年　北インド　ヒマラヤにてヨーガ研修<br />
2008年　インド　リシケシのシヴァナンダアシュラムにて研修<br />
2018年　健康ヨーガ教室30周年記念祭開催</p>
<h5 class="my-2">&lt;メッセージ&gt;</h5>
                                            <p>若い頃から旅行が何よりも好きでした。中でも自然にひたる旅に心惹かれます。<br />国内の旅のほかにアメリカ、ヨーロッパ、インド、中国、カンボジア、ペルーを訪れました。<br />
80代になってからもドイツ、スリランカを旅しました。<br />
病弱だった幼少期には考えられないほど、健康に恵まれ、<br />
楽しい旅を生涯続けられた幸せは、何よりもヨーガのおかげと感じています。</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="row justify-content-center m-1 border">
                                    <div className="col-lg-4 m-3 p-3 instructor_wrap">
                                        <Image src="./images/img_yoga_inst_takeda.jpg" alt="yoga" className="img_instructor"/>
                                    </div>
                                    <div className="description_wrap col-lg-7 m-3 p-3">
                                        <h4 className="name">武田真弓<span>（タケダ　マユミ）</span></h4>
                                        <div className="subm">
                                            <h5 class="my-2">&lt;経歴&gt;</h5>
                                            <p>メニエール病の予後ヨーガと出会い、2004年　髙山葉子氏に師事。<br />
師のストイックなライフスタイルに感銘をうける。<br />
2005年よりヨーガセラピーを学び、その後様々なヨーガの研修に参加。<br />
2010年日本ヨーガ禅道友会入会。本格的に佐保田鶴治先生のヨーガ禅を学び、2016年より認定教師。<br />
ヨーガ指導歴13年。<br />
2016年より東京信用金庫ヨーガ教室講師。<br />
取得したディプロマは「ヨーガ・インストラクター」「ヨーガ・セラピー・インストラクター」どちらも(インド中央政府技術者認定)</p>
<h5 class="my-2">&lt;メッセージ&gt;</h5>
                                            <p>ジャンルを問わず本はよく読みますが、なぜか歴史小説が好きで、宮城谷昌光さんの古代中国ものにハマったことも…<br />
家から見える大きな柿の木に、いろいろな鳥のさえずりがして、日々いやされています。</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="row justify-content-center m-1 border">
                                <div className="col-lg-4 m-3 p-3 instructor_wrap">
                                        <Image src="./images/img_yoga_inst_kondou.jpg" alt="yoga" className="img_instructor"/>
                                    </div>
                                    <div className="description_wrap col-lg-7 m-3 p-3">
                                        <h4 className="name">近藤美佐子<span>（コンドウ　ミサコ）</span></h4>
                                        <div className="subm">
                                            <h5 class="my-2">&lt;経歴&gt;</h5>
                                            <p>健康のために始めたヨーガが、自身のライフスタイルに欠かせないものだと気づいた事から、<br />
・2010年日本ヨーガ禅道友会に入門し、本格的に佐保田鶴治先生の教えに従い、学びを深める。<br />
また、予防医学や食事など、健康のための生活習慣ついて興味があり、<br />
・2019年健康管理士一級指導員を取得し、ヨーガ指導の傍ら、健康のアドバイスなどを行っている。<br />
・ヨーガ歴１８年<br />
・ヨーガ指導歴8年<br />
・2020年日本ヨーガ禅道友会認定教師となり、日頃からヨーガを少しでも生活の中に取り入れて、「健康＝幸せ」である事、未来の自分をより生き生きしてもらうため、わかりやすいヨーガをお伝えするよう努めている。</p>
<h5 class="my-2">&lt;メッセージ&gt;</h5>
                                            <p>自然に触れる事が好きで、散歩をしたり、ガーデニングをしながら、草木、花、鳥を眺めていると幸せな気分です。<br />
２０年以上続けている手作り酵素は、春に野草を摘みに行きます。<br />
春の芽吹き、生命を感じながら春の山や野原を散策する、毎年の楽しみとなっています。<br />
人間本来の必要なエネルギーとヨーガ禅で、元気に過ごしています。全てに感謝の毎日です。</p>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                            <div className="row justify-content-center m-1 border">
                            <div className="col-lg-4 m-3 p-3 instructor_wrap">
                                        <Image src="./images/img_yoga_inst_kondou.jpg" alt="yoga" className="img_instructor"/>
                                    </div>
                                    <div className="description_wrap col-lg-7 m-3 p-3">
                                        <h4 className="name">近藤美佐子<span>（コンドウ　ミサコ）</span></h4>
                                        <div className="subm">
                                            <h5 class="my-2">&lt;経歴&gt;</h5>
                                            <p>健康のために始めたヨーガが、自身のライフスタイルに欠かせないものだと気づいた事から、<br />
・2010年日本ヨーガ禅道友会に入門し、本格的に佐保田鶴治先生の教えに従い、学びを深める。<br />
また、予防医学や食事など、健康のための生活習慣ついて興味があり、<br />
・2019年健康管理士一級指導員を取得し、ヨーガ指導の傍ら、健康のアドバイスなどを行っている。<br />
・ヨーガ歴１８年<br />
・ヨーガ指導歴8年<br />
・2020年日本ヨーガ禅道友会認定教師となり、日頃からヨーガを少しでも生活の中に取り入れて、「健康＝幸せ」である事、未来の自分をより生き生きしてもらうため、わかりやすいヨーガをお伝えするよう努めている。</p>
<h5 class="my-2">&lt;メッセージ&gt;</h5>
                                            <p>自然に触れる事が好きで、散歩をしたり、ガーデニングをしながら、草木、花、鳥を眺めていると幸せな気分です。<br />
２０年以上続けている手作り酵素は、春に野草を摘みに行きます。<br />
春の芽吹き、生命を感じながら春の山や野原を散策する、毎年の楽しみとなっています。<br />
人間本来の必要なエネルギーとヨーガ禅で、元気に過ごしています。全てに感謝の毎日です。</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="row justify-content-center m-1 border">
                                    <div className="col-lg-4 m-3 p-3 instructor_wrap">
                                        <Image src="./images/img_yoga_inst_hamakawa.jpg" alt="yoga" className="img_instructor"/>
                                    </div>
                                    <div className="description_wrap col-lg-7 m-3 p-3">
                                        <h4 className="name">濱川量子<span>（ハマカワ　カズコ）</span></h4>
                                        <div className="subm">
                                            <h5 class="my-2">&lt;経歴&gt;</h5>
                                            <p>1989年より、健康ヨーガ教室主宰。<br />
1992年12月　南インドシュリラマナ道場・ラーマクリシュナミッションにて研修<br />
1996年　日本ヨーガ禅道友会の教師に認定<br />
1997年　北インド　ヒマラヤにてヨーガ研修<br />
2008年　インド　リシケシのシヴァナンダアシュラムにて研修<br />
2018年　健康ヨーガ教室30周年記念祭開催</p>
<h5 class="my-2">&lt;メッセージ&gt;</h5>
                                            <p>若い頃から旅行が何よりも好きでした。中でも自然にひたる旅に心惹かれます。<br />国内の旅のほかにアメリカ、ヨーロッパ、インド、中国、カンボジア、ペルーを訪れました。<br />
80代になってからもドイツ、スリランカを旅しました。<br />
病弱だった幼少期には考えられないほど、健康に恵まれ、<br />
楽しい旅を生涯続けられた幸せは、何よりもヨーガのおかげと感じています。</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="row justify-content-center m-1 border">
                                    <div className="col-lg-4 m-3 p-3 instructor_wrap">
                                        <Image src="./images/img_yoga_inst_takeda.jpg" alt="yoga" className="img_instructor"/>
                                    </div>
                                    <div className="description_wrap col-lg-7 m-3 p-3">
                                        <h4 className="name">武田真弓<span>（タケダ　マユミ）</span></h4>
                                        <div className="subm">
                                            <h5 class="my-2">&lt;経歴&gt;</h5>
                                            <p>メニエール病の予後ヨーガと出会い、2004年　髙山葉子氏に師事。<br />
師のストイックなライフスタイルに感銘をうける。<br />
2005年よりヨーガセラピーを学び、その後様々なヨーガの研修に参加。<br />
2010年日本ヨーガ禅道友会入会。本格的に佐保田鶴治先生のヨーガ禅を学び、2016年より認定教師。<br />
ヨーガ指導歴13年。<br />
2016年より東京信用金庫ヨーガ教室講師。<br />
取得したディプロマは「ヨーガ・インストラクター」「ヨーガ・セラピー・インストラクター」どちらも(インド中央政府技術者認定)</p>
<h5 class="my-2">&lt;メッセージ&gt;</h5>
                                            <p>ジャンルを問わず本はよく読みますが、なぜか歴史小説が好きで、宮城谷昌光さんの古代中国ものにハマったことも…<br />
家から見える大きな柿の木に、いろいろな鳥のさえずりがして、日々いやされています。</p>
                                        </div>
                                    </div>
                                </div>                                

                                <div className="row justify-content-center m-1 border">
                                    <div className="col-lg-4 m-3 p-3 instructor_wrap">
                                        <Image src="./images/img_yoga_inst_kagari.jpg" alt="yoga" className="img_instructor"/>
                                    </div>
                                    <div className="description_wrap col-lg-7 m-3 p-3">
                                        <h4 className="name">KAGARI<span>（カガリ）</span></h4>
                                        <div className="subm">
                                            <h5 class="my-2">&lt;経歴&gt;</h5>
                                            <p>1987年　日本ヨーガ禅道友会に入門<br />
1989年　健康ヨーガ教室に入門<br />
1992年12月　南インドシュリラマナ道場・ラーマクリシュナミッションにて研修<br />
1994年～1997年　NHKカルチャーセンター青山教室にて指導<br />
1997年北インド　ヒマラヤにてヨーガ研修<br />
2000年　インド　ロナワラ　カイバリヤダーマヨーガ大学にて短期ディプロマ取得<br />
2000年　日本ヨーガ禅道友会の教師に認定<br />
2002年～スポーツプレックス代々木店店舗、コナミスポーツクラブ、フージャースウェルネス＆スポーツアリス店舗にて指導<br />
2008年　インド　リシケシのシヴァナンダアシュラムにて研修<br />
2015年よりパドマの会主宰</p>
<h5 class="my-2">&lt;メッセージ&gt;</h5>
                                            <p>20代は登山とスキーに夢中でした。リハビリでヨーガを続けるうちに、<br />
調身・調息・調心の素晴らしさに気づきました。<br />
さらに、インドの大地に出会い、風土、文化や哲学にも魅力を感じます。<br />
何千年という悠久の流れの中に身をおく心地よさ、<br />
ヨーガ禅の奥深さをご一緒に味わえれば幸いです。</p>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                        </Tabs>

                            </div>
                        </div>
                </div>
                </div>
                <h3 className="subtitle">体験レッスン申込み専用カレンダー</h3>
                <Yoyaku />
                <MyComponent />
            </section>
            <Footer />
        </>
    )
}