import React from 'react';
import Footer from '../Footer';
import {TinyButton as ScrollUpButton} from "react-scroll-up-button";
import 'react-tabs/style/react-tabs.css';
import '../Hula.css';
import '../Contact.css';

import { GoogleMap, LoadScript, InfoWindow } from "@react-google-maps/api";
import '../Gmap.css';

/* gmap */
const containerStyle = {
height: "450px",
width: "100%",
};

const center = {
lat: 35.74319691226782,
lng: 139.6063904611377,
};

const positionStudio = {
lat: 35.74319691226782,
lng: 139.6063904611377,
};  

const divStyle = {
background: "white",
fontSize: 7.5,
};
/* gmap */

export default function Hula(){
    return(
        <>
            <ScrollUpButton style={{background: '#DF8734', fill: 'rgb(255, 255, 255)', height: '32px', width: '32px'}} />
            <section className="hula lesson">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <p class="lesson__lesson_name">Contact</p>
                            <h2 className="lesson__top__title">アクセス・お問い合わせ</h2>
                        </div>
                    </div>
                </div>
                <div className="container main">
                    <div className="row justify-content-center">
                        <div className="schedule scheduleimage">
                            <img src="./images/img_contact_01.png" alt="" />
                        </div>
                    </div>
                    <div className="schedule">
                        <h3 className="subtitle">Studio Viitaの営業時間</h3>
                        <p>●月曜日～日曜日、水曜日と祝日は休館<br />
                        ●営業時間　<br class="responsive--sxs" />平日／ 10：00～16：00<br />
                       <span class="nichiyou">火曜日のみ／ 10：00～16：00、18：30～20：00</span></p>
                       <p className="juusho">〒177-0041<br />
                        東京都練馬区石神井町3丁目20-17<br />
                        KMスクエアビル　6階<br />
                        </p>
                        <p>西武池袋線の石神井公園駅南口改札を出て、ロータリーに面した丸亀製麺が１Fに入っているビルの６Fにスタジオヴィータはあります。</p>
                        <p className="contact__c">TEL：<a href="tel:0369131377">03-6913-1377</a></p>
                        <p className="contact__c">Mail：<a href="mailto:studio.viita@gmail.com">studio.viita@gmail.com</a></p>
                    </div>
                </div>
                <div>
                <div className="calendar">
                    <div className="gmap">
                    <LoadScript googleMapsApiKey="AIzaSyAx0t_bEbxgf7KNbztJ0xqPhjxmDyP0oIk">
                        <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={17}>
                        <InfoWindow position={positionStudio}>
                            <div style={divStyle}>
                            <h5>STUDIO VIITA</h5>
                            </div>
                        </InfoWindow>
                        </GoogleMap>
                    </LoadScript>
                    </div>
                </div>
                <div className="container contact__wrap">
                    <div className="schedule">
                        <h3 className="subtitle">お問い合わせ</h3>
                        <div className="contact__wrap">
                            <iframe id="conframe" name="conframe" title="conframe" width="100%" src="https://studio-viita.com/contactform/odsafhodisahoadishdoasifhdasoifhfadsoiahfdsodafshadfohdfaoiadfshdisosh.php" frameBorder={0} />
                        </div>
                    </div>
                </div>
            </div>
            </section>
            <Footer />
        </>
    )
}