import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
  return (
    <>
    <div className='cards'>
      <h2 className='text-center text_color text_change'>どのクラスも経験豊富な講師陣が<br className="responsive--lll" />若い方からミドルエイジ、シニア世代まで<br className="responsive--lll" />ていねいに寄り添います。</h2>
      <p className='title_bottom text-center margin-y text_change'>スタジオViitaのコンセプトは、初心者や、シニアに配慮したハートウォーミングな対応。<br className="responsive--lllm" />経験豊富なベテラン講師が、若い方からミドルエイジ、シニア世代までしっかり寄り添います。</p>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='./images/img_top_menu_yoga.jpg'
              text=' ヨーガは朝、昼、夜、シニアの4クラス。
              初心者、親子での参加も大歓迎です。
              体が硬くてもムリなくゆったりできるのがヨーガです。
              新しくできたスタジオのリラックス空間で、
              身体と呼吸を調えて、
              心身のバランスと穏やかな安定をめざしましょう。'
              label='ヨーガ'
              path='/yoga'
            />
            <CardItem
              src='./images/img_top_menu_hula.jpg'
              text='フラダンスは土曜日の昼クラス。
              ハワイが大好きで、古典フラ、現代フラを踊っています。
              雄大なハワイの大自然を感じながら、明るい日差しの中
              伸び伸びと育つハワイの植物のように、この新しいスタジオで
              皆さまと一緒に踊る事を心から愉しみたいと思います。'
              label='フラダンス'
              path='/hula'
            />
            <CardItem
              src='./images/img_top_menu_walk.jpg'
              text='エクササイズウォーキングは、日曜日の朝クラス。
              毎日必ず行うことで自然に「美」と「健康」が
              手に入るのが、エクササイズウォーキングです。
              まずは歩き方チェックから。
              「歩き方」を習ったことがない方が多いからこそ、
              その「効果」は絶大です。'
              label='エクササイズウォーキング'
              path='/walk'
            />
          </ul>
          <div className='container'>
            <ul className='cards__items top_menu_item m-auto'>
              
              <CardItem
                src='./images/img_top_menu_hule1.jpg'
                text='指で行う“点”の刺激ではなく、足裏で行う“面”での施術です。
                筋肉を広い範囲で効率よくほぐすためもみ返しもありません。リンパ液と血液の流れを整え、
                人が本来持つ自然治癒力を呼び戻します。
                '
                label='フーレセラピー'
                path='/foul'
              />
            </ul>
          </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Cards;