import React from 'react'
import '../NewTitle.css'
import '../topics.css'
import Footer from '../Footer'
import MyComponent from '../Gmap'

const sub = document.getElementById("sub");
function func1() {
    sub.style.width = sub.contentWindow.document.body.scrollWidth + "px";
    sub.style.height = sub.contentWindow.document.body.scrollHeight + "px";
}

const Topics = () => {
    return (
        <>
        <section className="lesson">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <p class="lesson__lesson_name">News</p>
                        <h2 className="lesson__top__title">お知らせ</h2>
                    </div>
                    <iframe id="topics__allframe" name="topicsframe" title="topicsframe" width="100%" height="100%" onload="func1()" src="https://studio-viita.com/calendar/topics_all/" frameBorder={0} />
                </div>
            </div>
        </section>
        <MyComponent />
        <Footer />
        </>
    )
}

export default Topics
