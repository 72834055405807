import React from 'react';
import Footer from '../Footer';
import Image from 'react-bootstrap/Image'
import {TinyButton as ScrollUpButton} from "react-scroll-up-button";
import 'react-tabs/style/react-tabs.css';
import '../Foul.css';
import '../Hula.css';
import '../Yoga.css';
import '../Home.css';
import '../Gmap.css';
import Yoyaku_foul from '../Yoyaku_foul';
import MyComponent from '../Gmap';

export default function Walk(){
    return(
        <>
            <ScrollUpButton style={{background: '#DF8734', fill: 'rgb(255, 255, 255)', height: '32px', width: '32px'}} />
            <section className="foul lesson">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <p class="lesson__lesson_name">Foulee Therapie</p>
                            <h2 className="lesson__top__title">リンパと血流を促し心と身体を癒すフーレセラピー。</h2>
                        </div>
                    </div>
                </div>
                <div class="lesson__topimage">
                    <img src="./images/img_foul_top.jpg" class="lesson__top__img" alt="" />
                </div>
                <div className="container main">
                    <div className="row justify-content-center">
                        <h3 className="subtitle"><img src="./images/fouleetheraple.jpg"  alt="" class="img-thumbnail border-0"/></h3>
                    </div>
                    <h3 className="subtitle__t subtitle">フーレセラピーとは</h3>
                    <div class="flex flex__foule">
                        <div className="responsive--lllm">
                            <img src="./images/img_foul_image02.jpg" alt="" />
                        </div>
                        <div className="flex__foule__img">
                            <p className=''>フーレセラピーは足を使った施術です。筋肉をほぐすことによって、リンパ液と血液の流れを良くし、人が本来持つ自然治癒力を呼び戻します。手を使った「点」の刺激ではなく、足裏の「面」を使った刺激なので、筋肉を傷めることなく広い範囲を効率よくほぐすことができます。圧力が一点に集中しないため、もみ返しもありません。<br /><br />筋肉がほぐれ、血流が良くなると体温が上昇します。体温の上昇により、代謝がアップすると、脂肪の燃焼率が上がります。冷え性、肩こり、腰痛、足のむくみ、便秘などの改善に効果があり、施術中はリラックスできるためストレスを和らげます。<br /><br />定期的に施術を受けることで、スリム効果、ヒップアップ、さらに内臓の働きの促進にも効果が期待できます。</p>
                            <div className="row justify-content-center">
                                <div className="col-6 responsive--lllm">
                                    <img src="./images/candle.jpg" alt="" />
                                </div>
                                <div className="col-8 responsive--sxs">
                                    <img src="./images/img_foul_image02.jpg" alt="" />
                                </div>  
                            </div>
                        </div>
                    </div>
                    <div>
                        <img src="./images/img_foul_image01.png" class="foul__3tsu" alt="" />
                        <div id="topics_res"></div>
                        <div className="subtext">
                            <p>※フーレセラピー協会のWebサイトより転用　<a href="http://www.foulee.co.jp/about/" target="_blank">http://www.foulee.co.jp/about/</a></p>
                            <p>※施術時は少なくとも食後30分以上経っていることが好ましいです。</p>
                            <p>※ご来店後は施術用のウエアにお着換えいただきます。（時計、指輪、ネックレス等は外してください）</p>
                            <p>※施術後は水分を摂る必要がありますので、ハーブティーをお出しします。</p>
                            <p>※お着換え、ティータイムはコースの時間には含まれていませんので、コース時間プラス、15分から20分は余裕を持ってお越しくださいますようお願いいたします。</p>
                            <p>※下記の症状がある方は施術をお断りする場合があります。</p>
                            <p>　〇アルコール摂取後の方</p>
                            <p>　〇37℃以上の熱がある方</p>
                            <p>　〇妊娠中の方</p>
                            <p>　〇手術後すぐの方</p>
                            <p>　〇重篤な内臓疾患のある方</p>
                            <p>　〇伝染性の疾患・急性炎症がある方</p>
                            <p>　〇進行した静脈瘤や外傷のある方</p>
                            <p>　体調にご心配がある方は必ず医師の診断と指示をお受けください。施術後体調が悪化した場合の責任は負いかねます。</p>
                        </div>
                    </div>
                </div>
                <div className="gray lesson">
                <div className="container main react-tabs">
                    <div className="justify-content-center my-5 instructor">
                        <h3 className='bold py-3 mt-1 mb-4 title'>Studio Viitaのフーレセラピストを紹介します。</h3>
                        <div className="col-lg-12">
                        <div className="row justify-content-center m-1 border">
                            <div className="col-lg-4 m-3 p-3 instructor_wrap">
                                <Image src="./images/img_foul_image01.jpg" alt="yoga" className="img_instructor"/>
                            </div>
                            <div className="description_wrap col-lg-7 m-3 p-3">
                                <h4 className="name">濱川縁<span>（ハマカワ　ユカリ）</span></h4>
                                <div className="subm">
                                    <h5 class="my-2">&lt;経歴&gt;</h5>
                                    <p>フーレセラピー協会　認定セラピスト</p>
                                    <h5 class="my-2">&lt;プロフィール&gt;</h5>
                                    <p>フーレセラピーは日本発祥のリラクゼーションですが、私がフーレセラピーに出会ったのは、トルコ共和国のイスタンブールでした。異国での子育てや仕事で、知らず知らずのうちにストレスを溜め込み、力の抜けない毎日を過ごす中、日本人のフーレセラピストに出会いました。全身を優しく踏まれると、疲れもストレスも、手先足先からすーっと抜けていく。その心地よさに魅せられ、幾度も通ううちに、日本に帰ったら是非この施術を学びたいと思う様になりました。今は、フーレセラピーの生理学的意味と、奥深さを知った上で、フーレセラピーならではの心地良さを少しでも多くの方に体験していただきたいと思っています。</p>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <h3 className="subtitle foul__yoyaku">
                <span className="foul__subtitle__u">Studio Viitaの会員でない方でも、ご予約いただけます。</span><br className="responsive--lllm" />
                スケジュールからご予約ください。</h3>
                <div className="container main">
                    <div className="row justify-content-center">
                        <div className="price mt-60">
                            <h3>料金</h3>
                            <p className='my-3'>
                            ・30分足裏徹底コース　3,300円（税込み）<br />
                            ・45分全身クイックコース　4,400円（税込み）<br />
                            ・60分全身コース　5,500円（税込み）<br />
                            ・90分全身じっくりコース　8,800円（税込み）<br />
                            ・120分全身スペシャルコース　9,900円（税込み）<br />
                            ※延長10分／1,100円<br />
                            ※Studio Viita会員の方は（税込み料金より）500円割引となります。
                            </p>
                        </div>
                    </div>
                </div>
                <Yoyaku_foul />
                <MyComponent />
            </section>
            <Footer />
        </>
    )
}